<template>
  <transition name="sc-transition-fade" mode="out-in" appear>
    <div class="analytics-workshop-report mb-5 mx-md-5 mx-lg-auto">
      <h2 class="text-center mb-4 fw-normal"><strong>Workshop</strong> Analytics</h2>

      <div ref="analyticsColumns" class="row"
           @vnode-mounted="analyticsColsMounted"
           @vnode-updated="analyticsColsUpdated"
           @vnode-unmounted="analyticsColsUnmounted">

        <AnalyticsEmptyTeaser v-if="showEmptyTeaser"/>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Summary</h5>
              <AnalyticsNoData :isLoading="workshopReportSummaryLoading"/>

              <ul v-if="!workshopReportSummaryLoading" class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ fmtInt(workshopReportSummary.page_views) }}</strong>
                  </div>
                  Slide views
                </li>
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ fmtInt(workshopReportSummary.resource_views) }}</strong>
                  </div>
                  File views
                </li>
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ fmtInt(workshopReportSummary.shared_items) }}</strong>
                  </div>
                  Shared items
                </li>
                <li class="list-group-item">
                  <button @click.prevent="exportSummaryCsv()" class="btn btn-link text-muted p-0"
                    v-sc-tooltip="'Export CSV file'"><ScIcon name="download"/> CSV</button>
                  <template v-if="userSummaryCsvDataLoading">
                    <ScIcon name="spinnerFW" class="mx-2 text-muted" />
                  </template>
                  <div class="csv-loading" v-if="userSummaryCsvDataLoading">CSV file download may take up to 60 seconds.</div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Top users</h5>
              <div class="d-flex justify-content-end">
                <div class="text-muted">Slide views</div>
              </div>

              <AnalyticsNoData :isLoading="workshopTopUsersLoading"/>
              <AnalyticsTopUsers
                  v-if="!workshopTopUsersLoading"
                  topUsersCountCsvTitle="Slide Views"
                  :hasOpenedCount="false"
                  :topUsers="workshopTopUsers"
                  @paginated="paginated"></AnalyticsTopUsers>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Slide views by day</h5>

              <AnalyticsNoData :isLoading="workshopSlideViewsByDayLoading" :noData="!workshopSlideViewsByDayHasValues"/>

              <line-chart v-if="!workshopSlideViewsByDayLoading && workshopSlideViewsByDayHasValues"
                          :data="chartData" :height="200"></line-chart>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Top groups</h5>
              <div class="d-flex justify-content-end">
                <div class="text-muted">Slide views</div>
              </div>

              <AnalyticsNoData :isLoading="workshopTopGroupsLoading"/>
              <AnalyticsTopGroups
                  v-if="!workshopTopGroupsLoading"
                  :workshopTopGroups="workshopTopGroups"
                  @paginated="paginated"></AnalyticsTopGroups>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Top presentations</h5>
              <div class="d-flex justify-content-end">
                <div class="text-muted">Slide views</div>
              </div>

              <AnalyticsNoData :isLoading="workshopTopShowcasesLoading"/>
              <AnalyticsTopShowcases
                  v-if="!workshopTopShowcasesLoading"
                  :topShowcases="workshopTopShowcases"
                  @paginated="paginated"></AnalyticsTopShowcases>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title text-center">Top devices</h5>
              <div class="d-flex justify-content-end">
                <div class="text-muted">Slide views</div>
              </div>

              <AnalyticsNoData :isLoading="workshopTopDevicesLoading"/>
              <AnalyticsTopDevices
                  v-if="!workshopTopDevicesLoading"
                  :workshopTopDevices="workshopTopDevices"
                  @paginated="paginated"></AnalyticsTopDevices>
            </div>
          </div>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
    import $ from 'jquery';
    import Masonry from 'masonry-layout';
    import LineChart from './LineChart.vue';
    import ScNotification from '../../../shared/common/ScNotification.vue';
    import AnalyticsMixin from './AnalyticsMixin';
    import AnalyticsNoData from './AnalyticsNoData.vue';
    import AnalyticsTopShowcases from './AnalyticsTopShowcases.vue';
    import AnalyticsTopUsers from './AnalyticsTopUsers.vue';
    import AnalyticsTopGroups from './AnalyticsTopGroups.vue';
    import AnalyticsTopDevices from './AnalyticsTopDevices.vue';
    import AnalyticsEmptyTeaser from "./AnalyticsEmptyTeaser.vue";
    import ScIcon from "@/shared/common/ScIcon.vue";

    let _testNotEnoughData = false;

    let DataManager = function() {
      return {
        getWorkshopSummary(ajaxParams, vm) {
          if (vm.workshopReportSummaryLoading) return;
          vm.workshopReportSummary = {};
          vm.workshopReportSummaryLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_workshop_summary",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData && data)
              vm.workshopReportSummary = data;

          }).always(() => {
            vm.workshopReportSummaryLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading workshop summary');
          });
        },

        getWorkshopSlideViewsByDay(ajaxParams, vm) {
          if (vm.workshopSlideViewsByDayLoading) return;
          vm.workshopSlideViewsByDay.splice(0);
          vm.workshopSlideViewsByDayLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_workshop_total_views",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData && data && data.total_views)
              vm.workshopSlideViewsByDay = data.total_views;

          }).always(() => {
            vm.workshopSlideViewsByDayLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading slide views for workshop');
          });
        },

        getWorkshopTopUsers(ajaxParams, vm) {
          if (vm.workshopTopUsersLoading) return;
          vm.workshopTopUsers.splice(0);
          vm.workshopTopUsersLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_workshop_user_summary",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData && data && data.users)
              vm.workshopTopUsers = data.users;

          }).always(() => {
            vm.workshopTopUsersLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading top users for workshop');
          });
        },

        getWorkshopTopGroups(ajaxParams, vm) {
          if (vm.workshopTopGroupsLoading) return;
          vm.workshopTopGroups.splice(0);
          vm.workshopTopGroupsLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_workshop_group_summary",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData && data && data.groups)
              vm.workshopTopGroups = data.groups;

          }).always(() => {
            vm.workshopTopGroupsLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading top groups for workshop');
          });
        },

        getWorkshopTopShowcases(ajaxParams, vm) {
          if (vm.workshopTopShowcasesLoading) return;
          vm.workshopTopShowcases.splice(0);
          vm.workshopTopShowcasesLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_workshop_top_showcases",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData && data && data.top_showcases)
              vm.workshopTopShowcases = data.top_showcases;

          }).always(() => {
            vm.workshopTopShowcasesLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading top presentations for workshop');
          });
        },

        getWorkshopTopDevices(ajaxParams, vm) {
          if (vm.workshopTopDevicesLoading) return;
          vm.workshopTopDevices.splice(0);
          vm.workshopTopDevicesLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_workshop_top_devices",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData && data && data.top_devices)
              vm.workshopTopDevices = data.top_devices;

          }).always(() => {
            vm.workshopTopDevicesLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading top devices for workshop');
          });
        }
      }
    };
    let _dataManager = null;

    export default {
      name: "AnalyticsForWorkshop",
      mixins: [ AnalyticsMixin ],
      components: {
        ScIcon, AnalyticsTopShowcases, AnalyticsTopUsers, AnalyticsTopGroups, AnalyticsTopDevices, LineChart,
        AnalyticsNoData, AnalyticsEmptyTeaser },
      props: {
        ajaxParams: {}
      },
      data () {
        return {
          testCounter: 0,
          workshopReportSummary: {},
          workshopReportSummaryLoading: false,
          workshopSlideViewsByDay: [],
          workshopSlideViewsByDayLoading: false,
          workshopSlideViewsByDayHasValues: false,
          workshopTopUsers: [],
          workshopTopUsersLoading: false,
          workshopTopGroups: [],
          workshopTopGroupsLoading: false,
          workshopTopShowcases: [],
          workshopTopShowcasesLoading: false,
          workshopTopDevices: [],
          workshopTopDevicesLoading: false,
          userSummaryCsvDataLoading: false,

          /* vue-chartjs */
          chartData: {
            labels: [],
            datasets: [{
              scChartTooltipPrefix: 'Slide views',
              backgroundColor: 'transparent',
              lineTension: 0,
              borderColor: '#008ab0',
              pointBackgroundColor: '#008ab0',
              pointRadius: 4,
              pointHoverRadius: 5,
              pointHoverBorderColor: 'rgba(0, 128, 163, .5)',
              data: []
            }]
          }
        };
      },
      mounted () {
        _dataManager = new DataManager();
        this.loadData();

        setInterval(() => {
          this.testCounter++;
        }, 5000);
      },

      methods: {
        analyticsColsMounted () {
          this.$msnry = new Masonry( this.$refs.analyticsColumns, {
            itemSelector: '.analytics-grid-item',
            percentPosition: true
          });
        },
        analyticsColsUpdated () {
          if (this.$msnry) {
            this.$msnry.reloadItems();
            this.$msnry.layout();
          }
        },
        analyticsColsUnmounted () {
          if (this.$msnry) {
            this.$msnry.destroy();
            this.$msnry = null;
          }
        },
        paginated () { // triggered masonry update after pagination
          this.$nextTick(() => {
            this.analyticsColsUpdated();
          });
        },
        loadData () {
          _dataManager.getWorkshopSummary(this.ajaxParams, this);
          _dataManager.getWorkshopSlideViewsByDay(this.ajaxParams, this);
          _dataManager.getWorkshopTopUsers(this.ajaxParams, this);
          _dataManager.getWorkshopTopGroups(this.ajaxParams, this);
          _dataManager.getWorkshopTopShowcases(this.ajaxParams, this);
          _dataManager.getWorkshopTopDevices(this.ajaxParams, this);
        },
        exportSummaryCsv() {
          if (this.userSummaryCsvDataLoading) {
            return;
          }

          const startDate = this.ajaxParams.startDate;
          const endDate = this.ajaxParams.endDate;
          const days = 90;
          if (this.isDateRangeGreaterThanDays(startDate, endDate, days)) {
            ScNotification.growlErrMsg('This report can only be downloaded within a 90 day range.');
            return;
          }

          this.userSummaryCsvDataLoading = true;
          $.ajax({
            url: "/main/analytics/ajax_get_user_summary_csv_data",
            data: this.ajaxParams
          }).done((data) => {
            let csvLinesArr = [];
            for (let entry of data.workshop_user_views) {
              csvLinesArr.push([
                entry.username,
                entry.presentation_name,
                entry.page_count,
                entry.open_num
              ]);
            }

            let csvName = 'workshop-presentations';
            this.exportCsv(csvName, ['user', 'presentation', 'number of slides viewed', 'number of opens'], csvLinesArr);
            this.userSummaryCsvDataLoading = false;
          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading csv data error');
          });
        },
        isDateRangeGreaterThanDays(startDate, endDate, days) {
          const start = new Date(startDate);
          const end = new Date(endDate);

          const timeDifference = end - start;
          const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
          return dayDifference > days;
        },
      },
      computed: {
        isSummaryEmpty () {
          return (parseInt(this.workshopReportSummary.page_views)
              + parseInt(this.workshopReportSummary.resource_views)
              + parseInt(this.workshopReportSummary.shared_items)) === 0
        },
        showEmptyTeaser () {
          if (this.workshopReportSummaryLoading) return false;
          return this.isDefaultDateRange(this.ajaxParams.startDate, this.ajaxParams.endDate) && this.isSummaryEmpty;
        }
      },
      watch: {
        workshopSlideViewsByDay (nv) {
          if (nv.length === 0)
            return;

          /* vue-chartjs */
          this.chartData.labels.splice(0);
          this.chartData.datasets[0].data.splice(0);
          this.workshopSlideViewsByDayHasValues = false;

          for (let entry of nv) {
            this.chartData.labels.push(entry['event_day']);
            this.chartData.datasets[0].data.push(Number(entry['count']));
            if (Number(entry['count']) > 0) this.workshopSlideViewsByDayHasValues = true;
          }
        },
        ajaxParams () {
          this.loadData();
        }
      },
    }
</script>

<style>

    svg {
      overflow: initial !important;
    }

    .csv-loading {
      color: red;
      font-size: 8px;
    }
</style>
